import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
  import './buttons.css';

class SocialMediaIcon extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[]
    }
  }
  render(){
    //console.log(this.props.src)
    return(
        <Col md={2} xs={2}><a href={this.props.href} className="socialMediaIconsA"><img alt={this.props.alt} src={this.props.src} /></a></Col>
        
    );
  }
}
export default SocialMediaIcon