var HU={
    MAIN1: "Üdvözöl az Automeasify",
    MAIN2: "Az #1 IT Üzemeltetési Szolgáltatás Automatizáló szoftver",
    MAIN3: "Automatizálja infrastruktúra működését a ügyfél kéréstől a tényleges operatív munkáig.",
    navbar: {
        menu1: "Integrációk",
        menu2: "Funkciók",
        menu3: "Miért mi?",
        menu4: "ROI kalkulátor"
    },
    button1: "Megtérülés (ROI) számítása",
    homeHeader1: "Hogyan működik az Automeasify?",
    homeContent1: "Azokra a folyamatokra összpontosítunk, amelyek az ügyféltől indulnak, körbejárják az üzemeltetést, és egy teljesen automatizált sikeres megoldással térnek vissza.",
    homeHeader2: "Miért velünk automatizáljon?",
    homeContent2: "Az Automeasify segítségével egyszerűen automatizálhatja a szolgáltatásmenedzsmentet és az IT-infrastruktúra üzemeltetését az ITIL módszertan keretében.",
    homeBox1Header: "Az ITSM szoftveréből",
    homeBox1: "Nincs szüksége új eszközre, csak használja a meglévő rendszerekkel, és az első naptól kezdjen el automatizálni.",
    homeBox2Header: "A mi platformunkra",
    homeBox2: "Automeasify képes csatlakozni a meglévő Szolgáltatásmenedzsmenthez és észleli a jegyeket.",
    homeBox3Header: "Meglévő Infrastruktúrán belül",
    homeBox3: "Szoftverünk tudja kezelni az Ön jelenlegi infrastruktúráját, és könnyen integrálható vele.",
    homeBox4Header: "Összekötve az alkalmazásaival",
    homeBox4: "Az Ön alkalmazásai a mi platformunkkal. Fedezze fel integrációinkat...",
    homeHeader3: "Nagyszerű termékekkel integrálódunk",
    homeBoxes3: "Hamarosan...",
    homeContent3: "Fedezze fel az összes integrációnkat ...",
    homeHeader4: "Világos és egyszerűsített kialakítás",
    homeContent4: "A felhasználói igény önkiszolgálásától a tényleges feladat megoldásáig és annak megfelelő módszertan szerinti adminisztrációjáig csak egyetlen gombnyomásra van szükség.",
    contactHeader: "Készen áll rá, hogy még ma elkezdjen automatizálni?",
    contactContent1: "Még mindig bizonytalan az #1 IT Üzemeltetési Szolgáltatás Automatizáló szoftverrel történő automatizálásban?",
    contactContent2: "Azért vagyunk itt, hogy segítsünk Önnek, és alig várjuk, hogy a közeljövőben közös sikereket érjünk el az Automeasify segítségével.",
    contactBoxHeader: "Kérjen részletes leírást rólunk!",
    contactBoxInput1: "Az Ön keresztneve",
    contactBoxInput2: "Az Ön vezetékneve",
    contactBoxInput3: "Az Ön email címe",
    contactBoxText1: "A négyzet bejelölésével elolvasta és elfogadja a következőket ",
    contactBoxText2: "Általános Szerződési Feltételek",
    contactBoxText3: "Adatkezelési tájékoztató",
    contactBoxText4:"és ",
    footerQuote:"Automatizálja az IT Infrastruktúra üzemeltetését az igényléstől az tényleges operatív munkán át, egyetlen kattintással",
    footerHeader1: "Tudjon meg többet",
    footerMenu1: "Hogyan műkodik",
    footerMenu2: "Automatizálás",
    footerMenu3: "IT Automatizálás",
    footerHeader2: "Funkciók",
    footerMenu4: "ITSM funkciók",
    footerMenu5: "Üzemeltetési funkciók",
    footerMenu6: "Jelenlegi integrációk",
    footerHeader3: "Rólunk",
    footerMenu7: "Blog",
    footerMenu8: "Kontakt",
    footerMenu9: "Árajánlat kérés",
    footerHeader4: "Kövessen minket!",
    button2: "Kérjen részletes leírást",
    button3: "",
    button4: "Elküldés",
    button5: "Vissz a cikkekhez",
    integrationsHeader: "Integrációk",
    integrationsContent: "Jelenleg elérhető integrációink",
    integrationsBoxHead: "Hamarosan...",
    contactHeader2: "Miért velünk automatizáljon?",
    functions: "Funkciók",
    functionsHead1: "Automatizált Szolgáltatásmenedzsment",
    functionsHead2: "Automatizált IT Infrasturktúra Üzemeltetés",
    functionsHead3: "Automatizált napi ismétlődő feladatok",
    functionsContent1: "Az Automeasify segítségével egyszerűen automatizálhatja a szolgáltatásmenedzsmentet és az IT-infrastruktúra működését az ITIL módszertan keretében",
    functionsContent2: "Tudjuk, hogy a teljes informatikai infrastruktúra kezelése nehéz és időigényes lehet. Az Automeasify segítségével könnyedén kezelheti konfigurációit, kiszolgálóit és alkalmazásait az infrastruktúrában.",
    functionsContent3: "Minden csapatnak vannak olyan feladatai, amelyeket naponta kell elvégezni. De miért pazarolja az idejét ezekre a feladatokra, ha automatizálhatja az Automeasify segítségével?",
    functionsBoxTitle1: "Önkiszolgáló felület",
    functionsBoxContent1: "A kérések, események, problémák vagy módosítások konvertálása tényleges feladatra",
    functionsBoxTitle2: "Jegyek megoldása",
    functionsBoxContent2: "Nemcsak a feladat vagy a folyamat valósul meg egy kattintással, hanem a jegykezelés is",
    functionsBoxTitle3: "Jegyek kategorizálása",
    functionsBoxContent3: "A vevő tényleges kérését / problémáját automatikusan feladattá konvertálja",
    functionsBoxTitle4: "Operátorhoz rendelés",
    functionsBoxContent4: "Beállíthatja a tényleges csoportot, aki az egyedi automatizálásokat kezeli",
    functionsBoxTitle5: "Infrastruktúra kezelése",
    functionsBoxContent5: "Automeasify könnyedén tudja kezelni az IT Infrastruktúráját, olyanokat mint: VMWare, Hyper-V, Azure, AWS",
    functionsBoxTitle6: "Változások kezelése a környezetben",
    functionsBoxContent6: "Egy kattintással átméretezheti a virtuális gép lemezét az ITSM eszköz változásának megnyitásával és dokumentálásával",
    functionsBoxTitle7: "Script futtatás",
    functionsBoxContent7: "Illessze össze a szkriptet a megfelelő jegy típussal. Adjon hozzá egy szkriptet egy adott jegytípushoz, és automatizálja azt a maga módján",
    functionsBoxTitle8: "Automatizálásra készen",
    functionsBoxContent8: "Az 1. naptól kezdve láthatja a tényleges automatizálást, csak élvezze az automatizált informatikai müködést",
    functionsBoxTitle9: "Automatizálja hibajegyeit",
    functionsBoxContent9: "Határozza meg az eseménytípusok következő lépéseit, például: virtuális gép erőforrás-felhasználása, felhasználói zárolás stb.",
    functionsBoxTitle10: "Hibák és problémák kezelése",
    functionsBoxContent10: "Valami új probléma vagy hiba? Hadd tanuljon a platform az új esetekből, hogy hosszú távon hatékonyabb legyen",
    functionsBoxTitle11: "Rendszerellenőrzések",
    functionsBoxContent11: "A napi unalmas feladat a múlté. Futtassa a rendszer- és eszközellenőrzéseket egy kattintással",
    functionsBoxTitle12: "KPI mutatók",
    functionsBoxContent12: "Tervezésünk szerint egyre több statisztikával rendelkezünk az IT-infrastruktúra tényleges KPI-jének kimutatásához",
    calculatorHead: "Számitson megtérülést (ROI)",
    calculatorContent: "Számítsa ki a megtérülését (ROI) az Automeasify használatával és mi elküldjük Önnek az eredményt email-ben! Tudja meg mekkora megtérülése lehet az IT Üzemeltetés automatizálásával.",
    calcFormLabel1: "IT Üzemeltetés csapat tagjainak száma",
    calcFormDescript1: "Hány ember van a vállalatában, aki az IT-üzemeltetés területén vagy szerepkörben dolgozik? (általában mérnökök, rendszergazdák stb.)",
    calcFormLabel2: "Ticketkezelésre fordított munkaidő százaléka",
    calcFormDescript2: "Az üzemeltető kollégák idejének hány százaléka megy ticketek és azzal kapcsolatos feladatok kezelésér?",
    calcFormLabel3: "Sztenderdizált kérések százaléka",
    calcFormDescript3: "Milyen százalékban vannak olyan kérdések amikre meglévő folyamatok épülnek, vagy építhetőek (pl. új felhasználó létrehozása)",
    calcFormLabel4: "Jegyek (kérések, hibák, problémák) száma hetente",
    calcFormDescript4: "Összes jegy/hibajegy amit az Üzemeltető kollégák kezelnek hetente",
    calcFormLabel5: "1 év teljes munkaidős egyenértéke (FTE) egy alkalmazottnak",
    calcFormDescript5: "Éves szinten milyen összeg (FTE) a vállalkozás számára 1 üzemeltető kolléga",
    calcButton: "Kalkuláció",
    blogAuthor: "Szerző",
    blogHeader: "IT Automatizálás -  Automeasify Blog",
    blogSearch: "Cikk keresése...",
    blogButton: "Keresés"

}
export default HU;