import React, { Component } from 'react';
import { Link } from "gatsby"
  import './footer.css';
  import { Row, Col, Form } from 'react-bootstrap';
  import Button5 from './button5.js';
  import footerPic from '../images/footer.png';
  import logo from '../images/logo.png';
  import logo2 from '../images/favico.png';
  import SocialMediaIcon from './socialmedia.js';
  import face from '../images/facebook.png';
  import linkedin from '../images/linkedin.png';
  import messanger from '../images/messanger.png';
  import twitter from '../images/twitter.png';
  import youtube from '../images/youtube.png';
  import HU from './hu-HU_lang.js';
import EN from './en-EN_lang.js';
class Footer extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[]
    }
  }
  
render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
        <Row>
          
              <div className="homeFooterRow">
                <Row className="footerRow">
                  <Col xs={12} xl={3} md={3}>
                    <img alt="automeasifyFooterLogo" src={logo} />
                    <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerQuote : EN.footerQuote}</p>
                  </Col>
                  <Col xs={12} xl={2} md={2}>
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerHeader1 : EN.footerHeader1}</h3>
                    <li className="footerLi">
                      <Link to="/funkciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu1 : EN.footerMenu1}</Link>
                    </li>
                    <li className="footerLi">
                      <Link to="/funkciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu2 : EN.footerMenu2}</Link>
                    </li>
                    <li className="footerLi">
                      <Link to="/funkciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu3 : EN.footerMenu3}</Link>
                    </li>
                  </Col>
                  <Col xs={12} xl={2} md={2}>
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerHeader2 : EN.footerHeader2}</h3>
                    <li className="footerLi">
                      <Link to="/integraciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu4 : EN.footerMenu4}</Link>
                    </li>
                    <li className="footerLi">
                      <Link to="/integraciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu5 : EN.footerMenu5}</Link>
                    </li>
                    <li className="footerLi">
                      <Link to="/integraciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu6 : EN.footerMenu6}</Link>
                    </li>
                  </Col>
                  <Col xs={12} xl={2} md={2}>
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerHeader3 : EN.footerHeader3}</h3>
                    <li className="footerLi">
                      <Link to="/blog">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu7 : EN.footerMenu7}</Link>
                    </li>
                    <li className="footerLi">
                      <Link to="/kontakt">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu8 : EN.footerMenu8}</Link>
                    </li>
                    <li className="footerLi">
                      <Link to="/kalkulator">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu9 : EN.footerMenu9}</Link>
                    </li>
                  </Col>
                  <Col xs={12} xl={3} md={2}>
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerHeader4 : EN.footerHeader4}</h3>
                    <Row className="followUs">
                      <SocialMediaIcon alt="kép1" src={face} href="https://www.facebook.com/automeasify" /> 
                      <SocialMediaIcon alt="kép1" src={messanger} href="https://www.facebook.com/automeasify" />
                      <SocialMediaIcon alt="kép1" src={linkedin} href="https://www.linkedin.com/company/automeasify/" />
                      <SocialMediaIcon alt="kép1" src={twitter}/>
                      <SocialMediaIcon alt="kép1" src={youtube}/>
                    </Row>
                    <Button5 />
                  </Col>
                </Row>
                <Row className="footerImgDiv"><img src={footerPic} /><img src={footerPic} /></Row>
              </div>
            </Row>
    );
  }
}
export default Footer