import React, { Component } from 'react';
import { Link } from "gatsby"
import './navbar.css';
import logo from '../images/logo.png';
import { Row } from 'react-bootstrap';
import HU from './hu-HU_lang.js';
import EN from './en-EN_lang.js';
import { slide as Menu } from 'react-burger-menu'

class Navbar extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      scrolling: true
    }
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
      const device = window.innerWidth
    //alert(device)
    if(device < 600){
      //alert(true)
      this.setState({
        mobile: true
      })
    }
    });
  }
  
render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
      <div>
        {this.state.mobile ? <MobileMenu /> : <nav className={this.state.scrolling ? "mainNavbar" : "mainNavbarOnScroll" }>
            <ul>
                <Row>
                    <li className="imgLi">
                    <Link to="/"><img src={logo} /></Link>
                    </li>
                    <li className="regularLi">
                    <Link to="/integraciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.navbar.menu1 : EN.navbar.menu1}</Link>
                    </li>
                    <li className="regularLi">
                    <Link to="/funkciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.navbar.menu2 : EN.navbar.menu2}</Link>
                    </li>
                    <li className="whyUsLi">
                    <Link to="/kontakt">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.navbar.menu3 : EN.navbar.menu3}</Link>
                    </li>
                    <li className="requestPricingLi">
                    <Link to="/kalkulator">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.navbar.menu4 : EN.navbar.menu4}</Link>
                    </li>
                </Row>
            </ul>
        </nav> }
      </div>
        
    );
  }
}

class MobileMenu extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      mobile: false,
      scrolling: true
    }
    
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
    });
  }
render(){
  var userLang = navigator.language || navigator.userLanguage;
  return(<div>
    <Menu>
      <a id="home" className="menu-item" href="/">{userLang === "hu-HU" || userLang === "hu-hu" ? "Főoldal" : "Homepage"}</a>
      <a id="about" className="menu-item" href="/integraciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.navbar.menu1 : EN.navbar.menu1}</a>
      <a id="about" className="menu-item" href="/funkciok">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.navbar.menu2 : EN.navbar.menu2}</a>
      <a id="about" className="menu-item" href="/blog">Blog</a>
      <a id="contact" className="menu-item" href="/kontakt">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.navbar.menu3 : EN.navbar.menu3}</a>
      <a id="integrations"className="menu-item--small" href="/kalkulator">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.navbar.menu4 : EN.navbar.menu4}</a>
    </Menu>
    <nav className={this.state.scrolling ? "mainNavbar" : "mainNavbarOnScroll" }>
        <ul>
            <Row>
                <li className="imgLi">
                <Link to="/"><img src={logo} /></Link>
                </li>
            </Row>
        </ul>
    </nav>
</div> );
}
}

export default Navbar