import React from "react"
import Navbar from "../components/navbar.js";
import Footer from '../components/footer.js';
export default function Layout({ children }) {
  return (
    <div className="mainPage">
        <Navbar />
      {children}
      <Footer />
    </div>
  )
}