var EN={
    MAIN1: "Welcome to Automeasify",
    MAIN2: "The #1 IT Operations Service Automation tool",
    MAIN3: "Automate your IT Operation from the service request to the actual operational work.",
    navbar: {
        menu1: "Integrations",
        menu2: "Features",
        menu3: "Why us?",
        menu4: "Request Pricing"
    },
    button1: "Calculate your ROI now",
    homeHeader1: "How Automeasify works?",
    homeContent1: "We focus on processes which are starting from the customer and go around the operation team and get back with a successful resolution fully automated.",
    homeHeader2: "Why automate with us?",
    homeContent2: "With Automeasify you can easily automate service management, and the operation of the IT infrastructure within the framework of the ITIL methodology.",
    homeBox1Header: "From your ITSM tool",
    homeBox1: "You don’t need any new tool, just use it with your exsiting system and start automate from day 1.",
    homeBox2Header: "To Our Platform",
    homeBox2: "Automeasify can connect with your Service manager and automaticly detect tickets.",
    homeBox3Header: "Inside Your Infrastructure",
    homeBox3: "Our software can use your current infrastructure and easily integrate with it.",
    homeBox4eader: "Connected to your apps",
    homeBox4: "Your applications with our platform. Explore our integrations to learn more about it.",
    homeHeader3: "We integrate with great products",
    homeBoxes3: "Coming soon",
    homeContent3: "Explore all of our integrations...",
    homeHeader4: "Clear and simplified design",
    homeContent4: "From the self service of the user requirement to the solution of the actual task and its administration according to the corresponding methodology, all it takes is a single push of a button.",
    contactHeader: "Are you ready to start automate today?",
    contactContent1: "Still uncertain about automating with the #1 IT Operations Service Automation tool?",
    contactContent2: "We are here to help you and looking forward to achieve common successes in the near future with Automeasify.",
    contactBoxHeader: "Request detailed description about us!",
    contactBoxInput1: "Enter your First name",
    contactBoxInput2: "Enter your Last name",
    contactBoxInput3: "Enter your Email address",
    contactBoxText1: "By checking this box, you accept the",
    contactBoxText2: "General Terms and Conditions",
    contactBoxText3: "Privacy Policy",
    contactBoxText4:"and the",
    footerQuote:"Automate your IT Operation from the service request to the actual operational work with ease of one click",
    footerHeader1: "Learn more",
    footerMenu1: "How it works",
    footerMenu2: "How to automate",
    footerMenu3: "Ease of one click",
    footerHeader2: "Features",
    footerMenu4: "ITSM features",
    footerMenu5: "Operation features",
    footerMenu6: "Current integrations",
    footerHeader3: "About us",
    footerMenu7: "Our blog",
    footerMenu8: "Contact us",
    footerMenu9: "Request pricing",
    footerHeader4: "Follow us!",
    button2: "Ask for a detailed description",
    button3: "",
    button4: "Request",
    button5: "Go back to Blog page",
    integrationsHeader: "Integrations",
    integrationsContent: "Currently our available integrations",
    integrationsBoxHead: "Coming soon",
    contactHeader2: "Why choose us for automating?",
    functions: "Features",
    functionsHead1: "Automate Service management",
    functionsHead2: "Automate IT Infrastructure Operations",
    functionsHead3: " Daily repetative tasks",
    functionsContent1: "With Automeasify you can easily automate service management, and the operation of the IT infrastructure within the framework of the ITIL methodology",
    functionsContent2: "We know that managing the whole IT infrastructure can be a hard and time consuming. With Automeasify you can easily manage your configurations, servers, applications inside you infrastructure.",
    functionsContent3: "Every team have tasks which need to be done daily. But why waste time on that tasks if you can automate it with Automeasify?",
    functionsBoxTitle1: "Self service portal",
    functionsBoxContent1: "Converting the requests, incidents, problems or changes to an actual task.",
    functionsBoxTitle2: "Ticket resolution",
    functionsBoxContent2: "Not only the task or process will be done with one click but the ticket handling is solved as well.",
    functionsBoxTitle3: "Ticket categorization",
    functionsBoxContent3: "Predict the actual request/problem from the customer to which task we need to done.",
    functionsBoxTitle4: "Operator assignment",
    functionsBoxContent4: "You can set the actual department who will handle unique automations",
    functionsBoxTitle5: "Managing Infrastructure",
    functionsBoxContent5: "Automeasify can manage your IT Infrastructure with ease, like VMWare, Hyper-V, Azure, Digital Ocean",
    functionsBoxTitle6: "Changes in environments",
    functionsBoxContent6: "One click to resize a VM disk with opening and documenting the change in your ITSM tool",
    functionsBoxTitle7: "Script running",
    functionsBoxContent7: "Match your script with your ticket type. Add a script to a specific ticket type and automate it in your way",
    functionsBoxTitle8: "Ready to automate",
    functionsBoxContent8: "You can see the actual automation from day 1, just implement it and enjoy your automated IT Operations.",
    functionsBoxTitle9: "Automate incidents",
    functionsBoxContent9: "Define next steps to incident types like: VM resource usage, User lockout, etc.",
    functionsBoxTitle10: "Errors and problems",
    functionsBoxContent10: "Some new problem or error? Let the platform learn from the new cases to be more efficent on the long term",
    functionsBoxTitle11: "System checks",
    functionsBoxContent11: "Daily boring task are for the past. Run you daily system and device checks with one click",
    functionsBoxTitle12: "Review KPI",
    functionsBoxContent12: "By design we have more and more statistics to see the actual KPIs of your IT Infrastructure",
    calculatorHead: "Callculator your ROI",
    calculatorContent: "Calculate your return on investment (ROI) using Automeasify and we will email you the result! Find out how much you can pay off by automating IT Operations.",
    calcFormLabel1: "IT Operations team members",
    calcFormDescript1: "How many people in your company who works in IT Operations field or role? (usually Engineers, System Administrators, etc.)",
    calcFormLabel2: "Percentage of time goes to ticketing",
    calcFormDescript2: "What percentage of the time of operating colleagues goes to handling tickets and related tasks?",
    calcFormLabel3: "Standard requests percentage",
    calcFormDescript3: "What is the percentage of issues on which existing processes are built or can be built (eg creating a new user)",
    calcFormLabel4: "Tickets (request, incident) per week",
    calcFormDescript4: "All tickets handled weekly by the Operator colleagues",
    calcFormLabel5: "Cost of 1 Year FTE for one IT Operations employe",
    calcFormDescript5: "Annual amount (FTE) for the company for 1 operating colleague",
    calcButton: "Kalkuláció",
    blogAuthor: "Author",
    blogHeader: "Blog of Automeasify",
    blogSearch: "Search for post..",
    blogButton: "Search"
}
export default EN;
//  var userLang = navigator.language || navigator.userLanguage;
//  import HU from '../bin/hu-HU_lang.js';
//  import EN from '../bin/en-EN_lang.js'; 
//  {userLang === "hu-HU" ? HU.calculatorHead : EN.calculatorHead}